
.hero-style {
  top: 6rem;
  max-height: 24rem;
}

.hero-section {
  background-image: url("./assets/img/home-bg.jpeg");
  background-size: cover;
}

.contact-us {
  background-image: url("./assets/img/contact-bg.jpeg");
  background-size: cover;
}

.mh-monitor-bg {
   background-image: url("./assets/img/monitor-bg.jpeg");
   background-size:cover;
}

.mh-beyond-bg {
  background-image: url("./assets/img/beyond-bg.jpeg");
  background-size:cover;
}

.mh-security-bg {
  background-image: url("./assets/img/security-bg.jpeg");
  background-size:cover;
}

.mh-ops-bg {
  background-image: linear-gradient(to right, #000000ee, #ececec00), url("./assets/img/dev-ops-bg.jpeg");
  background-size:cover;
}

.mh-vpn-bg {
  background-image: url("./assets/img/vpn-bg.jpeg");
  background-size:cover;
}

.faded-bg-white {
  background: #ffffff80;
  border-radius: 5px;
}

.faded-bg-black {
  background: #0000007e;
  border-radius: 5px;
}

.mhIcon {
  width: 3rem !important;
  height: 3rem !important;
}

.mhIconSmall {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.mhIconSmaller {
  width: 2rem !important;
  height: 2rem !important;
}

.mhIconLarge {
  width: 3.25rem !important;
  height: 3.25rem !important;
}

.msgBlock {
  padding: 1em;
  font-size: 1em;
  margin: 0px;
  text-align: left;
  display: block;
  border-radius: 1px;
  align-self: flex-start;
  min-height: 1em;
  line-height: 1.5em;
  margin: 20px 0;
}

.successStyle {
  border: 3px solid #00703c;
  color: #00703c;
}

.failedStyle {
  border: 3px solid #b00020;
  color: #b00020;
}

.summaryHeader {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 7px;
}

.failedStyle > .summaryHeader {
  color: #b00020;
}
.successStyle > .summaryHeader {
  color: #00703c;
}